
import { defineComponent } from 'vue'
import TmVideoModalWistia from '@/components/shared/TmVideoModalWistia.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { commingSoonId } from '@/definitions/_general/_data/video'

export default defineComponent({
  components: { TmButton, TmVideoModalWistia },
  props: {
    videoId: {
      type: String,
      default: commingSoonId,
    },
  },
})
