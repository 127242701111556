
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const loadScript = (src: string) => {
      const newScript = document.createElement('script')
      newScript.setAttribute('src', src)
      newScript.async = true
      document.body.appendChild(newScript)
    }

    onMounted(() => {
      loadScript(`https://fast.wistia.com/embed/medias/${props.videoId}.jsonp`)
      loadScript('https://fast.wistia.com/assets/external/E-v1.js')
    })
  },
})
