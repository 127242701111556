
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import ChatWhatsAppSendersDropdown from '@/components/pages/chats/settings/whatsApp/ChatWhatsAppSendersDropdown.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import Highlight from '@/components/shared/Highlight.vue'

export default defineComponent({
  components: {
    TmTable,
    ChatWhatsAppSendersDropdown,
    TmBadge,
    TmStatus,
    TmCountry,
    Highlight,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
  },
})
