import type { PageContentSidebarItem } from '@/definitions/_layout/pageContent/sidebar/types'

export const inboxSidebarRoutes: PageContentSidebarItem[] = [
  {
    itemType: 'link',
    title: 'All settings',
    route: { name: 'base.chats.settings' },
  },
  {
    itemType: 'title',
    title: 'Channels',
  },
  {
    itemType: 'link',
    title: 'SMS, MMS and calls',
    route: { name: 'base.chats.settings.channels' },
  },
  {
    itemType: 'link',
    title: 'BYOC',
    route: { name: 'base.chats.settings.byoc' },
  },
  {
    itemType: 'link',
    title: 'WhatsApp',
    route: { name: 'base.chats.settings.whatsApp' },
  },
  {
    itemType: 'link',
    title: 'Facebook Messenger',
    route: { name: 'base.chats.settings.facebookMessenger' },
  },
  {
    itemType: 'link',
    title: 'Instagram',
    beta: true,
    betaText: 'new',
    route: { name: 'base.chats.settings.instagram' },
  },
  {
    itemType: 'link',
    title: 'Web widgets',
    route: { name: 'base.chats.settings.webWidgets' },
  },
  {
    itemType: 'title',
    title: 'Compliance',
  },
  {
    itemType: 'link',
    title: '10DLC registration',
    route: { name: 'base.chats.settings.10dlc' },
  },
  {
    itemType: 'link',
    title: 'Toll-free verification',
    route: { name: 'base.chats.settings.tollFree' },
  },
  {
    itemType: 'title',
    title: 'Other settings',
  },
  {
    itemType: 'link',
    title: 'General',
    route: { name: 'base.chats.settings.general' },
  },
  {
    itemType: 'link',
    title: 'Call settings',
    route: { name: 'base.chats.settings.call' },
  },
  {
    itemType: 'link',
    title: 'Sender settings',
    route: { name: 'base.chats.settings.sender' },
  },
  {
    itemType: 'link',
    title: 'Auto-responders',
    route: { name: 'base.chats.settings.autoResponders' },
  },
  {
    itemType: 'link',
    title: 'Filtered views',
    route: { name: 'base.chats.settings.filteredViews' },
  },
  {
    itemType: 'link',
    title: 'Chat statuses',
    route: { name: 'base.chats.settings.statuses' },
  },
]
