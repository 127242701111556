
import { defineComponent } from 'vue'
import FaqBlock from '@/components/shared/templates/FaqBlock.vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'

export default defineComponent({
  components: {
    FaqBlock,
    TmExpansion,
  },
})
