import type { AccountListType } from '@/definitions/chats/settings/common/types'
import type { SenderLine, SenderNumberLine } from '@/definitions/chats/settings/whatsApp/types'

export const SendersMock: SenderLine[] = [
  {
    id: 1,
    title: 'Nestle USA',
    numbers: [
      {
        id: 'us',
        name: '(345) 434-7654',
      },
      {
        id: 'us',
        name: '(345) 434-7655',
      },
    ],
    whatsAppId: 279339758589575,
    status: {
      name: 'Approved',
      color: 'green',
      outline: true,
    },
  },
  {
    id: 2,
    title: 'Nestle Canada',
    numbers: [
      {
        id: 'uk',
        name: '+44 7355 577146',
      },
    ],
    whatsAppId: 589575279758339,
    status: {
      name: 'Approved',
      color: 'green',
      outline: true,
    },
  },
]

export const SenderNumbersMock: SenderNumberLine[] = [
  {
    id: 1,
    title: 'Nestle USA',
    number: {
      id: 'us',
      name: '(345) 434-7654',
    },
    status: {
      name: 'online',
      color: 'green',
      outline: true,
    },
    rating: {
      color: 'green',
      text: 'High',
    },
    limits: '1k сustomers/24hr',
  },
  {
    id: 2,
    title: 'Nestle USA Support',
    number: {
      id: 'us',
      name: '(345) 434-7655',
    },
    status: {
      name: 'offline',
      color: 'gray',
      outline: true,
    },
    rating: {
      color: 'orange',
      text: 'Medium',
    },
    limits: '250 сustomers/24hr',
  },
]

export const LinkNumbersMock: SenderNumberLine[] = [
  {
    id: 1,
    title: 'Nestle USA Payments',
    number: {
      id: 'us',
      name: '(345) 434-7653',
    },
    status: {
      name: 'not linked',
      color: 'orange',
      outline: true,
    },
  },
  {
    id: 2,
    title: 'Nestle USA Support',
    number: {
      id: 'uk',
      name: '+44 7355 577146',
    },
    status: {
      name: 'not linked',
      color: 'orange',
      outline: true,
    },
  },
  {
    id: 3,
    title: 'Nestle USA Sales',
    number: {
      id: 'no',
      name: '+315786553273',
    },
    status: {
      name: 'not linked',
      color: 'orange',
      outline: true,
    },
  },
  {
    id: 4,
    title: 'Nestle USA Support',
    number: {
      id: 'us',
      name: '(345) 434-7564',
    },
    status: {
      name: 'linked',
      color: 'green',
      outline: true,
    },
  },
  {
    id: 5,
    title: 'Nestle USA',
    number: {
      id: 'us',
      name: '(345) 434-7565',
    },
    status: {
      name: 'linked',
      color: 'green',
      outline: true,
    },
    disabled: true,
  },
]

export const whatsAppAccountList: AccountListType[] = [
  {
    icon: require('@/assets/images/byoc/meta.svg'),
    title: 'Facebook by Meta',
    description: 'Click the Login with Facebook button to log in to your Facebook Business Account.',
  },
  {
    icon: require('@/assets/images/byoc/whatsapp.svg'),
    title: 'WhatsApp Business',
    description: 'Follow the guided setup to connect your WhatsApp Business number or to register a new one.',
  },
]
