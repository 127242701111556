
import { defineComponent, ref, computed } from 'vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import ChatWhatsAppEmpty from '@/components/pages/chats/settings/whatsApp/ChatWhatsAppEmpty.vue'
import ChatWhatsAppSendersFilter from '@/components/pages/chats/settings/whatsApp/ChatWhatsAppSendersFilter.vue'
import ChatWhatsAppSendersTable from '@/components/pages/chats/settings/whatsApp/ChatWhatsAppSendersTable.vue'
import ChatWhatsAppFaq from '@/components/pages/chats/settings/whatsApp/ChatWhatsAppFaq.vue'
import { useModes } from '@/compositions/modes'
import type { TableHeaders } from '@/definitions/shared/types'
import type { SenderLine } from '@/definitions/chats/settings/whatsApp/types'
import { SendersMock } from '@/definitions/chats/settings/whatsApp/data'

export default defineComponent({
  components: {
    WhiteBlock,
    DetailsHero,
    ChatsSettingsPage,
    ChatWhatsAppEmpty,
    ChatWhatsAppSendersFilter,
    ChatWhatsAppSendersTable,
    ChatWhatsAppFaq,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'WhatsApp' },
    ]

    const search = ref('')
    const selected = ref<SenderLine[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'WhatsApp Business account', value: 'account-slot' },
      { text: 'Numbers linked', value: 'number-slot' },
      { text: 'WhatsApp Account ID', value: 'whatsapp-id-slot' },
      { text: 'Status', value: 'status-slot' },
    ])
    const tableItems = ref<SenderLine[]>(SendersMock)
    const filteredTableItems = computed<SenderLine[]>(() => {
      return tableItems.value.filter((item) => item.title.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      isEmptyMode,
      breadcrumbs,
      search,
      selected,
      tableHeaders,
      tableItems,
      filteredTableItems,
    }
  },
})
